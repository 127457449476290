// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/tmp/build_290ece6f/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-custom-page-js": () => import("/tmp/build_290ece6f/src/templates/customPage.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-tour-js": () => import("/tmp/build_290ece6f/src/templates/tour.js" /* webpackChunkName: "component---src-templates-tour-js" */),
  "component---src-templates-homepage-js": () => import("/tmp/build_290ece6f/src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-pages-404-js": () => import("/tmp/build_290ece6f/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

